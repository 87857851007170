import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import './assets/awesome/css/all.css'; // Importa el archivo CSS de FontAwesome
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import PaginaInicio from './components/PaginaInicio.vue';
import PaginaPedido from './components/PaginaPedido.vue';
import PaginaEncuentranos from './components/PaginaEncuentranos.vue';
import PaginaPrecios from './components/PaginaPrecios.vue';
import FinalizarPedido from './components/FinalizarPedido.vue';
import FichasTecnicas from './components/FichasTecnicas.vue';
import VerPedido from './components/VerPedido.vue';

const routes = [
  {
    path: '/',
    component: PaginaInicio,
    meta: { title: 'Horno Goimar • Inicio' },
  },
  {
    path: '/encuentranos',
    component: PaginaEncuentranos,
    meta: { title: 'Horno Goimar • Encuéntranos' },
  },
  {
    path: '/precios',
    component: PaginaPrecios,
    meta: { title: 'Horno Goimar • Precios' },
  },
  {
    path: '/pedido',
    component: PaginaPedido,
    meta: { title: 'Horno Goimar • Haz tu pedido' },
  },
  {
    path: '/finalizar',
    component: FinalizarPedido,
    meta: { title: 'Horno Goimar • Finalizar pedido' },
  },
  {
    path: '/fichas-tecnicas',
    component: FichasTecnicas,
    meta: { title: 'Horno Goimar • Fichas técnicas' },
  },
  {
    path: '/pedido/:id',
    component: VerPedido,
    meta: { title: 'Horno Goimar • Mi pedido' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
  devtools: false,
});

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(router);
app.use(ElementPlus);

app.mount('#app');
