<template>
    <div>
        <form v-loading="loading" @submit.prevent="handlePayment">
            <div class="mt-3">
                <label for="card-number">Número de tarjeta</label>
                <div id="card-number"></div>
            </div>
            <div>
                <label for="card-expiry">Fecha de expiración</label>
                <div id="card-expiry"></div>
            </div>
            <div>
                <label for="card-cvc">CVC</label>
                <div id="card-cvc"></div>
            </div>
            <div id="card-errors" role="alert"></div>
            <button type="submit" :disabled="isProcessing">
                {{ isProcessing ? "Procesando..." : `Pagar (${cantidad.toFixed(2)}€)` }}
            </button>
        </form>
        <div>
            <form>
        <button style="background-color: #636e72;" class="mt-1" @click="volver()">
           Cambiar método de pago
        </button>
    </form>
    </div>
    </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { ElNotification } from 'element-plus'
export default {
    props: {
        paymentId: {
            type: String, // Tipo esperado (puedes cambiarlo según el tipo de tu identificador)
            required: true, // Indica que esta prop es obligatoria
        },
    },
    data() {
        return {
            stripe: null,
            elements: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
            isProcessing: false,
            clientSecret: null, // Aquí guardamos el secret obtenido del backend
            cantidad: 0,
            loading: false,
            cliente: '',
        };
    },
    async mounted() {
        this.stripe = await loadStripe("pk_live_51JxkB5Hlt49lBQBMyaJsUY2OcdzNHpXaIaYeBYCX29dA3Njlp0z1HzjJp0ymBUUtphp5ItzUWuUpi2A8hQDUyTR100MnKIDEJw");

        if (this.stripe) {
            this.elements = this.stripe.elements();

            // Crear los campos separados
            this.cardNumber = this.elements.create("cardNumber");
            this.cardExpiry = this.elements.create("cardExpiry");
            this.cardCvc = this.elements.create("cardCvc");

            // Montar los campos
            this.cardNumber.mount("#card-number");
            this.cardExpiry.mount("#card-expiry");
            this.cardCvc.mount("#card-cvc");

            // Obtener el client_secret desde el backend
            await this.getClientSecret();
        }
    },
    methods: {
        volver() {
            this.$emit('volver')
        },
        async getClientSecret() {
            this.loading = true;
            try {
                // Hacer una solicitud al backend para obtener el client_secret
                const response = await fetch(process.env.VUE_APP_BACKEND_URL + "/api/payment/initiate", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        identificador: this.paymentId
                    }),
                });
                if (response.status == 200) {
                    const data = await response.json();

                    if (data.error) {
                        ElNotification({
                            title: 'Error',
                            message: 'Ha ocurrido un error intentando procesar el pago',
                            type: 'error',
                        })
                    } else {
                        this.clientSecret = data.client_secret; // Guardar el client_secret
                        this.cantidad = data.cantidad;
                        this.cliente = data.cliente;
                    }
                } else {
                    ElNotification({
                        title: 'Error',
                        message: 'Ha ocurrido un error intentando procesar el pago',
                        type: 'error',
                    })
                    this.$emit('actualizarEstado')
                }
            } catch (error) {
                console.error("Error al obtener el client_secret:", error);
            }
            this.loading = false;
        },
        async handlePayment() {
            this.isProcessing = true;

            try {
                // Confirmar el pago con los datos ingresados
                const { error, paymentIntent } = await this.stripe.confirmCardPayment(
                    this.clientSecret,
                    {
                        payment_method: {
                            card: this.cardNumber, // Conectar los datos de los campos separados
                            billing_details: {
                                name: this.cliente,
                            },
                        },
                    }
                );

                if (error) {
                    console.error("Error al procesar el pago:", error.message);
                    ElNotification({
                            title: 'Error al procesar el pago',
                            message: error.message,
                            type: 'error',
                        })
                } else if (paymentIntent && paymentIntent.status === "succeeded") {
                    let resp = await fetch(process.env.VUE_APP_BACKEND_URL + '/api/payment/actualizar',
                        {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({ identificador: this.paymentId })
                        }
                    )
                    if (resp.status == 200) {
                        ElNotification({
                            title: '¡Bien!',
                            message: 'Tu pago se ha realizado correctamente. En breve recibirás un email de confirmación.',
                            type: 'success',
                        })
                        this.$emit('pagorealizado')
                    } else {
                        ElNotification({
                            title: 'Oops',
                            message: 'Ha ocurrido un error verificando tu pago.',
                            type: 'error',
                        })
                    }
                }
            } catch (error) {
                console.error("Error al confirmar el pago:", error);
            } finally {
                this.isProcessing = false;
            }
        },
    },
};
</script>

<style scoped>
* {
    color: #2f2f2f;
}

/* Estilo básico para los campos */
form {
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

#card-number,
#card-expiry,
#card-cvc {
    border: 1px solid #ddd;
    padding: 12px;
    border-radius: 4px;
    background-color: #f9f9f9;
    margin-bottom: 15px;
}

button {
    background-color: #2f2f2f;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
</style>