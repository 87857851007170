<!-- App.vue -->

<template class="dark">
  <div>
    <div id="app">
      <div v-if="$route.path !== '/fichas-tecnicas'" id="header">
        <h1><i class="fa-duotone fa-oven"></i> Horno<b>Goimar</b></h1>
        <router-link to="/">
          <h3 :class="$route.path == '/' ? 'active' : ''">Inicio</h3>
        </router-link>
        <router-link to="/encuentranos">
          <h3 :class="$route.path == '/encuentranos' ? 'active' : ''">Encuéntranos</h3>
        </router-link>
        <router-link to="/precios">
          <h3 :class="$route.path == '/precios' ? 'active' : ''">Precios</h3>
        </router-link>
        <router-link to="/pedido">
          <h3 :class="$route.path == '/pedido' ? 'active' : ''">Haz tu pedido</h3>
        </router-link>
      </div>
      <!-- Barra de navegación u otros elementos globales -->
      <router-view :cesta="cesta" :datosPersonales="datosPersonales" :onActualizarCesta="actualizarCesta"
        :onActualizarDatos="actualizarDatos" />

      <div v-if="$route.path !== '/fichas-tecnicas'" id="footer">
        Horno<b>Goimar</b>© {{ new Date().getFullYear() }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cesta: [],
      datosPersonales: {
        nombre: '',
        apellidos: '',
        email: '',
        telefono: '',
        dia: '',
        periodo: '',
      },
    };
  },
  // En el componente padre
  emits: [
    'actualizarCesta',
    'actualizarDatos'
  ],

  watch: {
    // Observa los cambios en la ruta actual y actualiza el título de la página
    '$route'({ meta }) {
      document.title = meta.title || 'Horno Goimar'; // Título predeterminado si la ruta no tiene título
    }
  },
  methods: {
    actualizarCesta(nuevo) {
      this.cesta = nuevo;
    },
    actualizarDatos(nuevo) {
      this.datosPersonales = nuevo;
    }
  }
}
</script>

<style lang="scss">
/* Importa la fuente desde Google Fonts (cambiar 'Open+Sans' por la fuente que desees) */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
}

body {

  margin: 0px;
}

a {
  text-decoration: none;
}

#app {
  min-height: 100vh;
  background-image: url('https://i.imgur.com/0dBNo7I.jpg');
  background-size: cover;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, transparent 100%);

  h1 {
    margin-top: 20px;
    font-weight: normal;
    margin-right: 50px;
  }

  h3 {
    margin: 10px;
    color: rgba(255, 255, 255, .5);
    font-weight: normal;
    cursor: pointer;

    &:after {
      display: block;
      content: '';
      border-bottom: solid 3px white;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover {
      color: white;
    }

    &.active {
      color: white;
      font-weight: bold;

      &:after {
        transform: scaleX(1);
      }
    }
  }
}

#footer {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 100%);
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;

  b {
    margin-right: 5px;
  }
}
</style>