<template>
    <div id="contenedorGeneral">
        <div id="contenedorEncuentranos">
            <transition name="el-fade-in-linear" @after-leave="mostrar = true">
                <div v-if="loading">
                    <img width="100" src="@/assets/img/roscon-de-reyes.png" class="fa-fade" />
                    <p><b>Cargando...</b></p>
                </div>
            </transition>
            <transition name="el-fade-in-linear">
                <div v-if="mostrar"
                    :style="`width: 100%; transition: 1s; width: 100%; transition: all 1s ease 0s; background: white; border-radius: 10px; color: #434242;`">
                    <h2>Pedido {{ pedido.identificador }}</h2>
                    <div class="content">
                        <div class="order-summary">
                            <p><span class="label">Día de recogida:</span> {{ pedido.recogida.mostrar }}</p>
                            <p><span class="label">Hora de recogida:</span> Entre las {{ pedido.recogida.hora_inicio }}
                                y las {{ pedido.recogida.hora_fin }}</p>
                        </div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Precio Unitario</th>
                                    <th>Cantidad</th>
                                    <th>Precio Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="linea in pedido.lineas" :key="linea.id">
                                    <td>Rosco {{ buscarTamano(linea.tamano).mostrando }} {{
                                        buscarRelleno(linea.relleno).mostrando }}</td>
                                     <td style="text-align: right;">{{ linea.precio_unidad.toFixed(2) }} €</td>
                                    <td style="text-align: right;">{{ linea.cantidad }}</td>
                                    <td style="text-align: right;">{{ (linea.cantidad * linea.precio_unidad).toFixed(2) }} €</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="3">Total pedido</th>
                                    <td style="text-align: right;">{{total}} €</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div class="order-summary">
                            <p><span class="label">QR de recogida</span></p>
                            <p style="text-align: center;"><img
                                    :src="`${backend}/generate-qr/${pedido.identificador}`"
                                    :alt="`QR Recogida ${pedido.identificador}`"></p>
                            <p>Puedes agilizar tu recogida mostrándonos este código</p>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PaginaPrecios',
    mounted() {
        this.init();
    },
    data: function () {
        return {
            tamanos: {},
            rellenos: {},
            loading: true,
            mostrar: false,
            pedido: false,
            backend: process.env.VUE_APP_BACKEND_URL
        }
    },
    computed: {
        idPedido() {
            return this.$route.params.id;
        },
        total() {
            let total = 0;
            this.pedido.lineas.map((linea) => {
                total += linea.precio_unidad * linea.cantidad
            })
            return total.toFixed(2);
        }
    },
    methods: {
        buscarTamano(valor) {
            return this.tamanos.find((tamano) => tamano.valor === valor);
        },
        buscarRelleno(valor) {
            return this.rellenos.find((relleno) => relleno.valor === valor);
        },
        async init() {
            await fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerDatosRoscos')
                .then(response => response.json())
                .then(data => {
                    this.tamanos = data.tamanos;
                    this.rellenos = data.rellenos;
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            let resp = await fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerDatosPedido', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ identificador: this.idPedido })
            });
            if (resp.status == 200) {
                this.pedido = await resp.json();
                this.loading = false;
                this.mostrar = true;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.header,
.footer {
    text-align: center;
    padding: 20px;
    background-color: #f3f3f3;
}

.header img {
    max-width: 150px;
}

.content {
    padding: 20px 30px;
}

.content h1 {
    font-size: 20px;
    color: #363a41;
    margin: 0 0 20px;
}

.content p {
    font-size: 16px;
    color: #363a41;
    line-height: 1.5;
    margin: 0 0 15px;
}

.content .highlight {
    font-weight: 700;
}

.order-summary {
    background-color: #fefefe;
    border: 1px solid #dfdfdf;
    padding: 10px 20px;
    text-align: left;
}

.order-summary p {
    margin: 5px 0;
}

.order-summary .label {
    font-weight: 700;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.table th,
.table td {
    border: 1px solid #dfdfdf;
    padding: 10px;
    text-align: left;
    font-size: 14px;
}

.table th {
    background-color: #ebecee;
    font-weight: 600;
}

.footer a {
    text-decoration: none;
    color: #656565;
    font-weight: 600;
}

small {
    color: #656565;
}

#contenedorGeneral {
    display: flex;
    align-items: center;
    justify-content: center;
}

#contenedorEncuentranos {
    border-radius: 5px;
    padding: 10px;
    transition: 0.5s;
    width: 100%;
    text-align: center;
    color: white;

    h2 {
        background: #2f2f2f;
        padding: 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-bottom: 20px;
        color: white;
    }

    button {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: none;
        margin: 0px;
        width: 100%;
        padding: 25px;
        color: white;
        font-size: 1.2rem;
    }

    @media(min-width: 600px) {
        width: 75%;
    }

    @media(min-width: 900px) {
        width: 50%;
    }

    @media(min-width: 1300px) {
        width: 30%;
    }
}
</style>